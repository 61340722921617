import React from 'react'
import { useSwipeable } from 'react-swipeable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsDown, faQuestion, faThumbsUp, faBars } from '@fortawesome/free-solid-svg-icons';

const DynamicStyles = (props) => {
    const obj = props.obj;
    const styles = `
      .canvas {
        width: ${obj.screenWidth}px;
        height: ${obj.screenHeight}px;
        position: relative;
        overflow: hidden;
        margin-bottom: 50px;
    }

      .header-area {
        height: ${obj.screenHeight - (obj.tableHeight + obj.tableHeaderSize) - (obj.bottomPadding * obj.devicePixelRatio)}px;
        position: absolute;
        width: ${obj.screenWidth}px;
        background-size: cover;
        background-position: center top;
    }

      .day-table {
        display: grid;
        grid-template-columns: repeat(${obj.stagesArray.length}, 1fr); /* One column per stage */
        grid-template-rows: auto repeat(${obj.totalMinutes}, 1fr); /* First row for stage names, rest for time slots */
        height: ${obj.tableHeight + obj.tableHeaderSize}px; /* Full height of the canvas */
        position: relative;
        width: ${obj.screenWidth-(obj.tableSideMargins * 2 * obj.devicePixelRatio)}px;
        margin-left: ${obj.tableSideMargins * obj.devicePixelRatio}px;
        margin-right: ${obj.tableSideMargins * obj.devicePixelRatio}px;
        font-size: ${obj.devicePixelRatio * 13}px;
        background: #000 url('/nebula.jpg') no-repeat;
        background-size: cover;
        line-height: 1;
    }

      .stage-name {
        font-weight: bold;
        text-align: center;
        grid-row: 1; /* Place the stage names in the first row */
        width: 100%; /* Full width of the stage column */
        background-color: rgba(0,0,0,.6);
        height: ${obj.tableHeaderSize}px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: 800;
    }
    `;

    return <style>{styles}</style>;
}


const CalendarView = (props) => {
    const handlers = useSwipeable({
        onSwipedLeft: () => {
            if (props.data?.html && props.selectedDate < props.data.html.length - 1) props.setSelectedDate((prev) => prev + 1);
        },
        onSwipedRight: () => {
            if (props.selectedDate > 0) props.setSelectedDate((prev) => prev - 1);
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true // Optional: Allows swipe detection with a mouse
    });
    
    return (
        <>
            <DynamicStyles 
                obj={props.screenObj}
                stagesArray={props.stagesArray}
            />
            {/* <div className="input-container">
                <select
                    value={props.personalLinkType}
                    id="personalLinkType"
                    name="personalLinkType"
                    onChange={(e) => props.setPersonalLinkType(e.target.value)}>
                    <option>QR Code Option</option>
                    <option>URL</option>
                    <option>Facebook</option>
                    <option>Instagram</option>
                    <option>X</option>
                    <option>WhatsApp</option>
                    <option>Phone Number</option>
                </select>
                {props.personalLinkType !== 'QR Code Option' && <input
                    name="personalUrl"
                    id="personalUrl"
                    value={props.personalUrl}
                    placeholder={props.placeholder}
                    disabled={props.personalLinkType === 'QR Code Option'}
                    onChange={(e) => props.setPersonalUrl(e.target.value)}
                />}
            </div> */}
            <div className="button-container">
                <button onClick={() => props.setSelection(2)} className={props.selection === 2 ? 'btn btn-ranking-2' : 'btn'}>
                    <FontAwesomeIcon icon={faThumbsDown} />
                </button>
                <button onClick={() => props.setSelection(3)} className={props.selection === 3 ? 'btn btn-ranking-3' : 'btn'}>
                    <FontAwesomeIcon icon={faQuestion} />
                </button>
                <button onClick={() => props.setSelection(4)} className={props.selection === 4 ? 'btn btn-ranking-4' : 'btn'}>
                    <FontAwesomeIcon icon={faThumbsUp} />
                </button>
                {/* <button onClick={() => props.setSelection(5)} className={props.selection === 5 ? 'btn btn-ranking-5' : 'btn'}>
                    <FontAwesomeIcon icon={faStar} />
                </button> */}
                <select
                    value={props.selectedDate}
                    id="selectedDate"
                    name="selectedDate"
                    onChange={(e) => props.setSelectedDate(parseInt(e.target.value))}>
                    <option value="0">Fri, Nov. 8</option>
                    <option value="1">Sat, Nov. 9</option>
                    <option value="2">Sun, Nov. 10</option>
                </select>
                <button onClick={() => props.setShowOptions(true)} className="btn" style={{flex: '3 1'}}>
                    <FontAwesomeIcon icon={faBars} /> Menu
                </button>
            </div>
            <div id="exportSourceInnerHTML"
                {...handlers}
                style={{
                    position: 'relative',
                    overflow: 'hidden',
                    width: `${(props.screenObj.screenWidth / props.screenObj.devicePixelRatio)}px`,
                    height: `${(props.screenObj.tableHeight + props.screenObj.tableHeaderSize) / props.screenObj.devicePixelRatio}px`,
                    touchAction: 'pan-y'
                }}>
                <div
                    id="dayPaneContainer"
                    style={{
                        height: `${(props.screenObj.tableHeight + (props.screenObj.screenWidth / 4) * (3 / 8)) / props.screenObj.devicePixelRatio}px`,
                        transform: `translateX(${props.translateX}px)`,
                        transition: 'transform 0.5s ease',
                        display: 'flex', // Make inner div flexible for swiping left/right
                        width: '100%', // Ensure full width for swiping
                    }}>
                    <div
                        dangerouslySetInnerHTML={{ __html: props.htmlContent }}
                        style={{
                            transform: `scale(${1 / props.screenObj.devicePixelRatio})`,
                            transformOrigin: '0 0',
                            width: `${props.screenObj.screenWidth}px`,
                            height: `${props.screenObj.tableHeight + props.screenObj.tableHeaderSize}px`
                            // height: `${window.screen.availHeight * window.devicePixelRatio}px`
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default CalendarView;