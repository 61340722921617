import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import "./App.css";
// import 'bootstrap/dist/css/bootstrap.css';
// import './styles/custom-btsp.scss';
import App from './App';
import { BrowserRouter } from "react-router-dom";

// if ('serviceWorker' in navigator) {
//   // Unregister all service workers
//   navigator.serviceWorker.getRegistrations().then((registrations) => {
//     const unregisterPromises = registrations.map((registration) => {
//       return registration.unregister().then(() => {
//         console.log('Service worker unregistered.');
//       });
//     });

//     // Once all service workers are unregistered, register the new one
//     Promise.all(unregisterPromises).then(() => {
//       window.addEventListener('load', () => {
//         navigator.serviceWorker
//           .register('/service-worker-images.js')
//           .then((registration) => {
//             console.log('Images Service Worker registered:', registration);
//           })
//           .catch((registrationError) => {
//             console.error('Images Service Worker registration failed:', registrationError);
//           });
//       });
//     });
//   }).catch((error) => {
//     console.error('Error unregistering service workers:', error);
//   });
// }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  // </React.StrictMode>
);
