import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiper } from 'swiper/react';
import "swiper/css";
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper/modules';

const HelpView = (props) => {
    const NextSlideButton = () => {
        const swiper = useSwiper();
        return (
            <button onClick={() => swiper.slideNext()} className="btn" style={{flex: .99}}>
                Next
            </button>
        );
    };

    return (
        <div className="modal">
            <div id="helpContent"            >
                <div id="helpContentText">
                    <h1>EDCO24 Schedule Maker</h1>

                    <Swiper navigation={true} pagination={true} modules={[Pagination, Navigation]} className="mySwiper">
                        <SwiperSlide>
                            <h2>Highlight your festival choices</h2>
                            <p>
                                Pick from three categories like the example below, <br />
                                then tap an artist's set to highlight. <br />
                                Swipe to see all three days!<br />

                            </p>
                            <img src="/helpRankings.gif" width="200" alt="Guide to Rankings" />
                            <div className="button-container">
                                <NextSlideButton />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <h2>Add your personalized QR code</h2>
                            <p>Select a social network or add a custom URL.<br />
                                Scan and connect with new friends!<br />
                                Can also help identify the device owner if lost.
                            </p>
                            <img src="/helpQrCodes.png" width="300" alt="Create a Personalized QR Code" />
                            <div className="button-container">
                                <NextSlideButton />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <h2>Download Device Wallpaper</h2>
                            <p>Select <strong>Create Wallpaper</strong><br />to generate images for each day.<br />
                                Follow device instructions to set up wallpaper,<br />
                                you can also post to social and share with friends!</p>
                            <h2 style={{ marginTop: '40px' }}>Never Lose Access</h2>
                            <p>Schedule automatically saves to this device!</p>
                            <div className="button-container">
                                <button onClick={() => {
                                    props.setShowHelp(false);
                                    localStorage.setItem('showHelp', true);
                                }
                                }
                                    className="btn"  style={{flex: .99}}>
                                    Let's Go!
                                </button>
                            </div>
                        </SwiperSlide>
                    </Swiper>

                    {/* <blockquote>Test Data Currently In Use</blockquote> */}


                    <h6>Images and schedule data are © Insomniac Events, Inc. All rights reserved. <br />
                        EDCO24 Schedule Maker is an unofficial app created by <a href="mailto:jeff@sunsetskycreative.com">Jeff P</a> @ <a target="_blank" rel="noreferrer" href="https://sunsetskycreative.com">Sunset Sky Creative</a></h6>
                    {localStorage.getItem('showHelp') && <div className="button-container">
                        <button onClick={() => {
                            props.setShowHelp(false);
                            props.setShowOptions(false);
                            props.setPersonalLinkType('QR Code Option');
                            props.setPersonalUrl('');
                            const newSelections = {
                                ...props.userSelections,
                                selections: []
                            }
                            props.setUserSelections(newSelections)
                            localStorage.setItem('savedSelections', JSON.stringify(newSelections));
                            props.setHtmlContent(localStorage.getItem('calendarHTML'))
                            props.setHtmlKey(Math.random());
                        }
                        }
                            className="btn btn-danger">
                            Reset All
                        </button>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default HelpView;