import React, { useState, useEffect, useRef, useCallback } from 'react';
import CalendarView from './components/CalendarView/CalendarView';
import OptionsView from './components/OptionsView/OptionsView';
import HelpView from './components/HelpView/HelpView';
import TagManager from 'react-gtm-module';

const screenWidth = window.screen.availWidth < window.screen.availHeight ? window.screen.availWidth * window.devicePixelRatio : 1080;
const screenHeight = window.screen.availWidth < window.screen.availHeight ? window.screen.availHeight * window.devicePixelRatio : 1920;
const devicePixelRatio = window.screen.availWidth < window.screen.availHeight ? window.devicePixelRatio : 2;

function App() {

  // Custom hook to detect the user's operating system
  const useDetectOS = () => {
    const [os, setOS] = useState(null);

    useEffect(() => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        setOS('Android');
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        setOS('iOS');
      } else {
        setOS('other');
      }
    }, []);

    return os;
  };

  const os = useDetectOS();
  const qrCodeSize = 60;
  const qrCodeTopMargin = os === 'Android' ? 90 : 122;
  const qrCodeSideMargin = os === 'Android' ? 40 : 5;
  const qrCodeBorderWidth = 2;

  const bottomPadding = os === 'Android' ? 68 : 28;
  const tableSideMargins = os === 'Android' ? 35 : 0;
  const tableHeaderSize = (screenWidth / 4) * (3 / 8);
  const topPadding = (tableHeaderSize / window.devicePixelRatio) + 200 - bottomPadding;
  const tableHeight = screenHeight - (topPadding * devicePixelRatio) - (bottomPadding * devicePixelRatio) - tableHeaderSize;
  const stagesArray = ['neonGARDEN', 'kineticFIELD', 'stereoBLOOM', 'circuitGROUNDS'];
  const minTime = 13; // 1:00 PM in decimal format
  const maxTime = 24; // 12:00 AM in decimal format
  const totalTimeRange = maxTime - minTime; // 11 hours total time range
  const totalMinutes = totalTimeRange * 60; // Convert hours to total minutes

  const [htmlContent, setHtmlContent] = useState(localStorage.getItem('calendarHTML') ? localStorage.getItem('calendarHTML') : '');
  const [htmlKey, setHtmlKey] = useState(Math.random());
  const [data, setData] = useState(null);
  const [selection, setSelection] = useState(4);
  const [personalLinkType, setPersonalLinkType] = useState(localStorage.getItem('personalLinkType') ? localStorage.getItem('personalLinkType') : 'QR Code Option');
  const [personalUrl, setPersonalUrl] = useState(localStorage.getItem('personalUrl') ? localStorage.getItem('personalUrl') : '');
  const [personalFullUrl, setPersonalFullUrl] = useState('');
  const [placeholder, setPlaceholder] = useState('');
  const [selectedDate, setSelectedDate] = useState(0);
  const [translateX, setTranslateX] = useState(0); // Translation state
  const [userSelections, setUserSelections] = useState(localStorage.getItem('savedStorage') ? JSON.parse(localStorage.getItem('savedSelections')) : { user: '12345678', selections: [] });
  const [showOptions, setShowOptions] = useState(false);
  const [showHelp, setShowHelp] = useState(localStorage.getItem('showHelp') == null);
  const previousDateRef = useRef(selectedDate);

  const callfn = useCallback((id) => {
    const element = document.getElementById(id);
    const artist = element.querySelector('.set-content span').textContent;

    if (element) {
      let newSelections = [...userSelections.selections];
      const selectionIndex = newSelections.findIndex(item => item.id === id);
      
      if (element.className.indexOf(`ranking-${selection}`) === -1) {
        element.className = `set-cell ranking-${selection}`;
        if (selectionIndex === -1) {
          newSelections.push({ id: id, rank: selection, sync: false });
        } else {
          newSelections[selectionIndex] = { id: id, rank: selection, sync: false }; // Update existing selection if needed
        }
        logButtonClick(artist,selection);
      } else {
        element.className = 'set-cell';
        if (selectionIndex !== -1) {
          newSelections.splice(selectionIndex, 1); // Remove the object at selectionIndex
        }
        logButtonClick(artist,0);
      }

      // Update userSelections with a new object reference
      setUserSelections({
        ...userSelections,
        selections: newSelections
      });
      localStorage.setItem('savedSelections', JSON.stringify({
        ...userSelections,
        selections: newSelections
      }))
    }
  }, [selection, userSelections]);

  const logButtonClick = (buttonName, additionalInfo) => {
    const tagManagerArgs = {
      gtmId: 'GTM-NJCT8JRC',
      dataLayer: {
        userProject: 'edco24',
        events: {
          event: buttonName,
          info: additionalInfo
        }
      },
      dataLayerName: 'EventDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs);
  };

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-NJCT8JRC',
      dataLayer: {
        userProject: 'edco24',
        page: window.location.pathname
      },
      dataLayerName: 'PageDataLayer'
    }
    TagManager.initialize(tagManagerArgs);
    TagManager.dataLayer(tagManagerArgs);
  }, []);

  useEffect(() => {
    window.callfn = callfn;
  }, [callfn]);

  useEffect(() => {
    localStorage.setItem('personalLinkType', personalLinkType)
  }, [personalLinkType]);

  useEffect(() => {
    localStorage.setItem('personalUrl', personalUrl)
  }, [personalUrl]);

  useEffect(() => {
    // Fetch the HTML content from the Node.js server
    fetch(`/api/schedule`)
      .then((response) => {
        if (response.ok) { // Checks if the status is in the range 200-299
          return response.json();
        } else {
          return null;
        }
      })
      .then((html) => {
        if (html) {
          setData(html); // Only set data if HTTP status is 200-299
        }
      })
      .catch((error) => {
      });
  }, [selection]);

  useEffect(() => {
    if (!localStorage.getItem('savedSelections') || htmlContent === '') return;
    const savedSelections = JSON.parse(localStorage.getItem('savedSelections'));
    savedSelections?.selections.forEach(item => {
      const element = document.getElementById(item.id);
      if (element) {
        element.className = `set-cell ranking-${item.rank}`;
      }
    })
    setUserSelections((prevUserSelections) => ({
      ...prevUserSelections,
      selections: savedSelections?.selections || []
    }));
  }, [htmlContent]);

  useEffect(() => {
    if (!data?.html) return;
    const html = data.html.map((item, index) => {
      return `<div class="dayPane" id="dayPane${index}" style="left: ${screenWidth * index}px;"}>${item}</div>`;
    }).join('');
    setHtmlContent(html); // Set the HTML content in state
    localStorage.setItem('calendarHTML', html);
    const previousDate = previousDateRef.current;
    const dateDifference = selectedDate - previousDate;

    // Calculate the new translateX based on the date difference
    setTranslateX((prev) => {
      return (prev - (dateDifference * (screenWidth / devicePixelRatio)))
    });

    // Update the previousDateRef to the current selectedDate for the next change
    previousDateRef.current = selectedDate;
  }, [selectedDate, data]);

  useEffect(() => {
    setPlaceholder(() => {
      switch (personalLinkType) {
        case "URL":
          return "URL (include http:// or https://)";
        case "Facebook":
          return "Facebook Account Handle or ID";
        case "Instagram":
          return "Instagram Account Handle";
        case "X":
          return "X Account Handle";
        case "TikTok":
          return "TikTok Account Handle";
        case "WhatsApp":
          return "WhatsApp Account Handle";
        case "Email":
          return "Email Address";
        case "Phone Number":
          return "Alt. Phone: (###) ###-####";
        default:
          return "";
      }
    });
  }, [personalLinkType]);

  useEffect(() => {
    setPersonalFullUrl(() => {
      switch (personalLinkType) {
        case "URL":
          return `${personalUrl}`;
        case "Facebook":
          return `https://facebook.com/${personalUrl}`;
        case "Instagram":
          return `https://instagram.com/${personalUrl}`;
        case "X":
          return `https://x.com/${personalUrl}`;
        case "TikTok":
          return `https://www.tiktok.com/@${personalUrl}`;
        case "WhatsApp":
          return `https://wa.me/${personalUrl.replace(/^\+|\D/g, (match, offset) => (offset === 0 && match === '+') ? '+' : '')}`;
        case "Email":
          return `mailto:${personalUrl}`;
        case "Phone Number":
          return `tel:${personalUrl.replace(/^\+|\D/g, (match, offset) => (offset === 0 && match === '+') ? '+' : '')}`;
        default:
          return `${personalUrl}`;
      }
    });
  }, [personalLinkType, personalUrl]);

  const screenObj = {
    qrCodeSize,
    qrCodeTopMargin,
    qrCodeSideMargin,
    qrCodeBorderWidth,
    screenWidth,
    screenHeight,
    devicePixelRatio,
    topPadding,
    bottomPadding,
    tableSideMargins,
    tableHeaderSize,
    tableHeight,
    stagesArray,
    minTime,
    maxTime,
    totalTimeRange,
    totalMinutes,
  }
  return (
    <>
      {showHelp &&
        <HelpView
          screenObj={screenObj}
          setShowHelp={setShowHelp}
          setPersonalLinkType={setPersonalLinkType}
          setPersonalUrl={setPersonalUrl}
          userSelections={userSelections}
          setUserSelections={setUserSelections}
          setHtmlContent={setHtmlContent}
          setHtmlKey={setHtmlKey}
          setShowOptions={setShowOptions}
        />
      }
      {showOptions && !showHelp &&
        <OptionsView
          screenObj={screenObj}
          setShowOptions={setShowOptions}
          personalLinkType={personalLinkType}
          setPersonalLinkType={setPersonalLinkType}
          personalUrl={personalUrl}
          setPersonalUrl={setPersonalUrl}
          personalFullUrl={personalFullUrl}
          setPersonalFullUrl={setPersonalFullUrl}
          placeholder={placeholder}
          setShowHelp={setShowHelp}
          logButtonClick={logButtonClick}
        />}
      <CalendarView
        key={htmlKey}
        screenObj={screenObj}
        stagesArray={stagesArray}
        data={data}
        htmlContent={htmlContent}
        translateX={translateX}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        selection={selection}
        setSelection={setSelection}
        setShowOptions={setShowOptions}
      />


    </>
  );
}

export default App;
