import React, { useRef, useState } from 'react'
import { useSwipeable } from 'react-swipeable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faQuestion, faTimes } from '@fortawesome/free-solid-svg-icons';
import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';

const OptionsView = (props) => {

    const [currentImage, setCurrentImage] = useState(0);
    const [wallpaperMessage, setWallpaperMessage] = useState('');
    const [translateX, setTranslateX] = useState(0); // Translation state
    const imageWidth = (props.screenObj.screenWidth / props.screenObj.devicePixelRatio) * .5;
    const leftMargin = ((props.screenObj.screenWidth / props.screenObj.devicePixelRatio) - imageWidth) / 2;

    const captureRef = useRef();
    const wallpaperGeneratingMessage = 'One Moment - Generating Wallpaper...';

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            if (currentImage === 2) return;
            setTranslateX((prev) => {
                return (prev - ((imageWidth + (leftMargin / 2))))
            });
            setCurrentImage((prev) => prev + 1);
        },
        onSwipedRight: () => {
            if (currentImage === 0) return;
            setTranslateX((prev) => {
                return (prev + ((imageWidth + (leftMargin / 2))))
            });
            setCurrentImage((prev) => prev - 1);
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true // Optional: Allows swipe detection with a mouse
    });

    const registerServiceWorker = async () => {
        if ('serviceWorker' in navigator) {
            try {
                await navigator.serviceWorker.register('/service-worker.js');
                console.log('Service Worker registered for wallpaper caching.');
            } catch (error) {
                console.error('Service Worker registration failed:', error);
            }
        }
    };

    const unregisterServiceWorker = async () => {
        if (navigator.serviceWorker) {
            const registrations = await navigator.serviceWorker.getRegistrations();
            for (let registration of registrations) {
                await registration.unregister();
                console.log('Service Worker unregistered after wallpaper generation.');
            }
        }
    };

    const handleCapture = async () => {
        props.logButtonClick('Download Wallpaper',props.personalFullUrl.trim())
        await registerServiceWorker(); // Register the service worker

        const CACHE_NAME = 'wallpaper-cache-v1';
        const destinationImages = document.getElementById('destinationImages');
        destinationImages.innerHTML = '';
        setWallpaperMessage(wallpaperGeneratingMessage);
        setTranslateX(0);
        setCurrentImage(0);

        for (let date = 0; date < 3; date++) {
            const element = captureRef.current;
            const headerHTML = `
                <div 
                    class="header-area" 
                    style="background-image: url('/header${date}.jpg');"
                >
                </div>`;
            const dateHTML = () => {
                switch (date) {
                    case 0:
                        return `
                        <div id="headerDay">Fri</div>
                        <div id="headerDate">Day 1</div>`;
                    case 1:
                        return `
                        <div id="headerDay">Sat</div>
                        <div id="headerDate">Day 2</div>`;
                    case 2:
                        return `
                        <div id="headerDay">Sun</div>
                        <div id="headerDate">Day 3</div>`;
                    default:
                        return '';
                }
            }
            const sourceHTML = `
                <div id="exportSourceInnerHTML" style="
                    position: relative;
                    overflow: hidden;
                    width: ${(props.screenObj.screenWidth / props.screenObj.devicePixelRatio)}px;
                    height: ${(props.screenObj.tableHeight + props.screenObj.tableHeaderSize) / props.screenObj.devicePixelRatio}px;
                ">

                <div id="dayPaneContainer" style="
                    height: ${(props.screenObj.tableHeight + (props.screenObj.screenWidth / 4) * (3 / 8)) / props.screenObj.devicePixelRatio}px;
                    display: flex;
                    width: 100%;
                ">

                <div style="
                    transform: scale(${1 / props.screenObj.devicePixelRatio});
                    transform-origin: 0 0;
                    width: ${props.screenObj.screenWidth - 100}px;
                    height: ${props.screenObj.tableHeight + props.screenObj.tableHeaderSize}px;
                ">
                
                ${document.getElementById('dayPane' + date).innerHTML}
               </div></div></div>
                `;
            document.getElementById('exportHeaderInnerHTML').innerHTML = headerHTML;
            document.getElementById('exportDestinationDateHTML').innerHTML = dateHTML();
            document.getElementById('exportDestinationInnerHTML').innerHTML = sourceHTML;

            const canvas = await html2canvas(element);
            const imgBlob = await new Promise((resolve) => canvas.toBlob(resolve, 'image/png'));

            // Define unique image path for service worker caching
            const cacheKey = `/generated-image-${date}.png`;
            if ('caches' in window && imgBlob) {
                const cache = await caches.open(CACHE_NAME);
                await cache.put(cacheKey, new Response(imgBlob));
            }

            // Retrieve the cached image URL and display it
            const imgElement = document.createElement('img');
            imgElement.src = URL.createObjectURL(imgBlob); // Use blob URL for immediate display
            imgElement.style.width = `${imageWidth}px`;
            imgElement.style.position = 'absolute';
            imgElement.style.marginLeft = `${leftMargin + (date * (imageWidth + (leftMargin / 2)))}px`;

            destinationImages.appendChild(imgElement);
        };
        setWallpaperMessage('Press/hold on each image and save to your photos, then set those as new wallpaper images on your device.');
        await unregisterServiceWorker(); // Unregister the service worker after use
    };
    
    // const handleCapture = async () => {
    //     await registerServiceWorker(); // Register the service worker

    //     const CACHE_NAME = 'wallpaper-cache-v1';
    //     const destinationImages = document.getElementById('destinationImages');
    //     destinationImages.innerHTML = '';
    //     setWallpaperMessage(wallpaperGeneratingMessage);
    //     setTranslateX(0);
    //     setCurrentImage(0);

    //     for (let date = 0; date < 3; date++) {
    //         const element = captureRef.current;
    //         const headerHTML = `
    //             <div 
    //                 class="header-area" 
    //                 style="background-image: url('/header${date}.jpg');"
    //             >
    //             </div>`;
    //         const dateHTML = () => {
    //             switch (date) {
    //                 case 0:
    //                     return `
    //                     <div id="headerDay">Fri</div>
    //                     <div id="headerDate">Day 1</div>`;
    //                 case 1:
    //                     return `
    //                     <div id="headerDay">Sat</div>
    //                     <div id="headerDate">Day 2</div>`;
    //                 case 2:
    //                     return `
    //                     <div id="headerDay">Sun</div>
    //                     <div id="headerDate">Day 3</div>`;
    //                 default:
    //                     return '';
    //             }
    //         }
    //         const sourceHTML = `
    //             <div id="exportSourceInnerHTML" style="
    //                 position: relative;
    //                 overflow: hidden;
    //                 width: 1024px;
    //                 height: ${(props.screenObj.tableHeight + props.screenObj.tableHeaderSize) / props.screenObj.devicePixelRatio}px;
    //             ">

    //             <div id="dayPaneContainer" style="
    //                 height: 800px;
    //                 display: flex;
    //                 width: 100%;
    //             ">

    //             <div style="
    //                 transform: scale(1);
    //                 transform-origin: 0 0;
    //                 width: 1024px;
    //                 height: 900px;
    //             ">
                
    //             ${document.getElementById('dayPane' + date).innerHTML}
    //            </div></div></div>
    //             `;
    //         document.getElementById('exportHeaderInnerHTML').innerHTML = headerHTML;
    //         document.getElementById('exportDestinationDateHTML').innerHTML = dateHTML();
    //         document.getElementById('exportDestinationInnerHTML').innerHTML = sourceHTML;

    //         const canvas = await html2canvas(element);
    //         const imgBlob = await new Promise((resolve) => canvas.toBlob(resolve, 'image/png'));

    //         // Define unique image path for service worker caching
    //         const cacheKey = `/generated-image-${date}.png`;
    //         if ('caches' in window && imgBlob) {
    //             const cache = await caches.open(CACHE_NAME);
    //             await cache.put(cacheKey, new Response(imgBlob));
    //         }

    //         // Retrieve the cached image URL and display it
    //         const imgElement = document.createElement('img');
    //         imgElement.src = URL.createObjectURL(imgBlob); // Use blob URL for immediate display
    //         imgElement.style.width = `${imageWidth}px`;
    //         imgElement.style.position = 'absolute';
    //         imgElement.style.marginLeft = `${leftMargin + (date * (imageWidth + (leftMargin / 2)))}px`;

    //         destinationImages.appendChild(imgElement);
    //     };
    //     setWallpaperMessage('Press/hold on each image and save to your photos, then set those as new wallpaper images on your device.');
    //     await unregisterServiceWorker(); // Unregister the service worker after use
    // };

    return (
        <div className="modal" style={{
            position: 'fixed',
            overflow: 'hidden',
            height: '100vh'
        }}>
            <div className="button-container">
                <button
                    onClick={() => props.setShowHelp(true)}
                    className="btn">
                    <FontAwesomeIcon icon={faQuestion} /> Help
                </button>
                <button
                    onClick={() => props.setShowOptions(false)}
                    className="btn">
                    <FontAwesomeIcon icon={faTimes} /> Close Menu
                </button>
            </div>
            <div className="input-container">
                <select
                    value={props.personalLinkType}
                    id="personalLinkType"
                    name="personalLinkType"
                    onChange={(e) => {
                        props.setPersonalLinkType(e.target.value)
                        props.setPersonalUrl('');
                    }}>
                    <option value="QR Code Option">{props.personalLinkType === 'QR Code Option' ? 'Select to add a personalized wallpaper QR Code' : 'None'}</option>
                    <option>Instagram</option>
                    <option>Facebook</option>
                    <option>X</option>
                    <option>TikTok</option>
                    <option>URL</option>
                    <option>WhatsApp</option>
                    <option>Email</option>
                    <option>Phone Number</option>
                </select>
                {props.personalLinkType !== 'QR Code Option' && <input
                    name="personalUrl"
                    id="personalUrl"
                    autoCapitalize="off"
                    autoComplete="off"
                    value={props.personalUrl}
                    placeholder={props.placeholder}
                    disabled={props.personalLinkType === 'QR Code Option'}
                    onChange={(e) => props.setPersonalUrl(e.target.value)}
                />}
            </div>
            <div className="button-container">
                <button onClick={handleCapture}
                    disabled={wallpaperMessage === wallpaperGeneratingMessage}
                    className="btn btn-primary">
                    <FontAwesomeIcon icon={faDownload} /> Create Wallpaper
                </button>
            </div>

            <div id="wallpaperMessage">
                {wallpaperMessage}
            </div>

            <div id="destinationImagesContainer" style={{ height: `${(imageWidth / props.screenObj.screenWidth) * (props.screenObj.screenHeight) + 52}px` }}>
                <div id="destinationImages" style={{ width: '1000px', height: `${(imageWidth / props.screenObj.screenWidth) * (props.screenObj.screenHeight) + 50}px`, touchAction: 'pan-y', transition: 'transform 0.5s ease', transform: `translateX(${translateX}px)`, }} {...handlers}>
                </div>
            </div>

            <div
                id="exportCanvas"
                className="canvas"
                ref={captureRef}
                style={{
                    width: props.screenObj.screenWidth,
                    height: props.screenObj.screenHeight,
                    position: 'absolute',
                    left: '-10000px',
                    top: '-10000px'
                }}>

                <div id="exportHeaderInnerHTML">
                </div>

                <div id="exportDestinationDateHTML"
                    style={{
                        top: props.screenObj.qrCodeTopMargin * props.screenObj.devicePixelRatio,
                        left: props.screenObj.qrCodeSideMargin * props.screenObj.devicePixelRatio,
                        width: props.screenObj.qrCodeSize * props.screenObj.devicePixelRatio,
                        height: props.screenObj.qrCodeSize * props.screenObj.devicePixelRatio,
                        fontSize: `${props.screenObj.devicePixelRatio * 20}px`
                    }}
                >
                </div>

                {props.personalUrl && <div id="qrCode"
                    style={{
                        top: props.screenObj.qrCodeTopMargin * props.screenObj.devicePixelRatio,
                        right: props.screenObj.qrCodeSideMargin * props.screenObj.devicePixelRatio,
                        width: props.screenObj.qrCodeSize * props.screenObj.devicePixelRatio,
                        height: props.screenObj.qrCodeSize * props.screenObj.devicePixelRatio,
                        borderWidth: props.screenObj.qrCodeBorderWidth * props.screenObj.devicePixelRatio
                    }}
                >
                    <QRCode
                        value={props.personalFullUrl.trim()}
                        size={props.screenObj.qrCodeSize * props.screenObj.devicePixelRatio}
                        bgColor="#ffffff"
                        fgColor="#000000"
                        level="H"
                    />
                </div>}

                <div id="exportDestinationInnerHTML" style={{
                    transform: `scale(${props.screenObj.devicePixelRatio})`,
                    transformOrigin: '0 0',
                    position: 'absolute',
                    height: `${props.screenObj.tableHeight + props.screenObj.tableHeaderSize}px`,
                    bottom: `${(props.screenObj.bottomPadding * props.screenObj.devicePixelRatio)}px`
                }}>
                </div>

                <div id="exportFooter" style={{
                    height: `${props.screenObj.bottomPadding * props.screenObj.devicePixelRatio}px`,
                    width: `${props.screenObj.screenWidth}px`,
                    fontSize: `${props.screenObj.devicePixelRatio * 10}px`
                }}>
                    made with ❤️ edc.sunsetskycreative.com
                </div>
            </div>
            
            {/* <div
                id="exportCanvas"
                className="canvas"
                ref={captureRef}
                style={{
                    width: '1024px',
                    height: '1024px',
                    position: 'absolute',
                    left: '-20000px',
                    top: '-20000px'
                }}>

                <div id="exportHeaderInnerHTML">
                </div>

                <div id="exportDestinationDateHTML"
                    style={{
                        top: props.screenObj.qrCodeTopMargin,
                        left: props.screenObj.qrCodeSideMargin,
                        width: props.screenObj.qrCodeSize,
                        height: props.screenObj.qrCodeSize,
                        fontSize: `20px`
                    }}
                >
                </div>

                {props.personalUrl && <div id="qrCode"
                    style={{
                        top: props.screenObj.qrCodeTopMargin * props.screenObj.devicePixelRatio,
                        right: props.screenObj.qrCodeSideMargin * props.screenObj.devicePixelRatio,
                        width: props.screenObj.qrCodeSize * props.screenObj.devicePixelRatio,
                        height: props.screenObj.qrCodeSize * props.screenObj.devicePixelRatio,
                        borderWidth: props.screenObj.qrCodeBorderWidth * props.screenObj.devicePixelRatio
                    }}
                >
                    <QRCode
                        value={props.personalFullUrl.trim()}
                        size={props.screenObj.qrCodeSize * props.screenObj.devicePixelRatio}
                        bgColor="#ffffff"
                        fgColor="#000000"
                        level="H"
                    />
                </div>} 

                <div id="exportDestinationInnerHTML" style={{
                    transform: `scale(1)`,
                    transformOrigin: '0 0',
                    position: 'absolute',
                    height: `${props.screenObj.tableHeight + props.screenObj.tableHeaderSize}px`,
                    bottom: `${(props.screenObj.bottomPadding)}px`
                }}>
                </div>

                <div id="exportFooter" style={{
                    height: `${props.screenObj.bottomPadding}px`,
                    width: `1024px`,
                    fontSize: `10px`
                }}>
                    made with ❤️ edc.sunsetskycreative.com
                </div>
            </div> */}
        </div>
    )
}

export default OptionsView;